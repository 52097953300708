import devGlobalConfig from '../../config/global-config-dev.json';
import localGlobalConfig from '../../config/global-config-local.json';
import productionGlobalConfig from '../../config/global-config-production.json';
import stagingGlobalConfig from '../../config/global-config-staging.json';

export const staticGlobalConfigByProject = {
  'medzy-local': localGlobalConfig,
  'medzy-dev': devGlobalConfig,
  'medzy-staging': stagingGlobalConfig,
  'medzy-production': productionGlobalConfig,
};

export * from './types';
