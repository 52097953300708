export const addOrRemove = <T>(arr: T[], item: T): T[] =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

export function maxBy<T>(arr: NonEmptyArray<T>, proj: (v: T) => number | string): T;
export function maxBy<T>(arr: T[], proj: (v: T) => number | string): T | undefined;
export function maxBy<T>(arr: T[], proj: (v: T) => number | string): T | undefined {
  if (arr.length === 0) return undefined;
  return arr
    .map<[T, number | string]>((a) => [a, proj(a)])
    .reduce((aWithProj, bWithProj) => (aWithProj[1] >= bWithProj[1] ? aWithProj : bWithProj))[0];
}

export function sumBy<T extends unknown>(arr: NonEmptyArray<T>, proj: (v: T) => number): number;
export function sumBy<T extends unknown>(arr: T[], proj: (v: T) => number): number | undefined;
export function sumBy<T extends unknown>(arr: T[], proj: (v: T) => number): number | undefined {
  if (arr.length === 0) return undefined;
  return arr.map(proj).reduce((a, b) => a + b, 0);
}

/** [a, b, b, a] => [a, b, a] */
export const consecutiveDeduplicateBy = <T, U>(list: T[], projection: (v: T) => U): T[] => {
  if (list.length === 0) return [];
  const result = [list[0]];
  let intermediate = projection(list[0]);
  for (const element of list) {
    const tmp = projection(element);
    if (intermediate !== tmp) {
      result.push(element);
      intermediate = tmp;
    }
  }

  return result;
};

export const distinctBy = <T, K>(arr: T[], proj: (v: T) => K): T[] => {
  const keys = arr.map(proj);
  return arr.filter((_v, i) => keys.indexOf(keys[i]) === i);
};

export const groupBy = <
  K extends string,
  T extends {[P in K]?: string | number | Date | null | undefined}
>(
  items: T[],
  key: K
): {
  [x: string]: T[];
  [x: number]: T[];
} =>
  items.reduce(
    function (acc, element) {
      // TypeScript only accepts numbers and strings as object indices
      // JavaScript accepts a lot of different types - Date, undefined, null - and silently converts them to strings
      const value = (element[key] as any) as number | string;
      (acc[value] = acc[value] || []).push(element);
      return acc;
    },
    {} as {
      [x: string]: T[];
      [x: number]: T[];
    }
  );

export type NonEmptyArray<T> = [T, ...T[]];

export const isEmpty = <T>(arr: T[]): arr is [] => {
  return arr.length === 0;
};
export const isNonEmpty = <T>(arr: T[]): arr is NonEmptyArray<T> => {
  return arr.length > 0;
};

// To prevent improper widening, make sure to specify the return type of f at the call site
// https://github.com/microsoft/TypeScript/pull/40311
export const choose = <T, U>(arr: T[], f: (v: T) => U | null | undefined): U[] => {
  const result: U[] = [];
  for (const v of arr) {
    const transformed = f(v);
    if (transformed !== null && transformed !== undefined) result.push(transformed);
  }
  return result;
};

export function head<T>(arr: NonEmptyArray<T>): T;
export function head(arr: []): undefined;
export function head<T>(arr: T[]): T | undefined;
export function head<T>(arr: T[]): T | undefined {
  return arr[0];
}
