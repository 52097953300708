import {nameCase} from '@foundernest/namecase';

type ObjectWithNameProps = {
  first_name?: string | null;
  last_name?: string | null;
  [key: string]: unknown;
};

export const formatName = (object: string | ObjectWithNameProps): string => {
  if (typeof object === 'string') return nameCase(object.trim());

  return [object.first_name?.trim(), object.last_name?.trim()]
    .filter((n) => n && n !== '')
    .map((n) => {
      if (!n) throw new Error('Name token is undefined but should not be');

      // Check if name token has parentheses, don't format if it does
      if (/\(([^)]+)\)/g.test(n.trim())) return n;

      return nameCase(n);
    })
    .join(' ');
};
