import React from 'react';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import {InvoiceAdjustmentFormValues} from 'ui/admin/orders/single-order/@components/invoice-info/invoice-adjustment/invoice-adjustment';
import {impossible} from 'utils/assert';
import {StyledInvoiceTable, StyledTableWrapper, StyledTdEmpty} from '../styles';
import {PatientData, ServiceInvoiceUpdateEvent, EditorState, PreferredLanguage} from '../types';
import ServiceInvoiceLineItem from './service-invoice-line-item';
import ServiceInvoiceTableFooter from './service-invoice-table-footer';
import ServiceInvoiceTableHeader from './service-invoice-table-header';

const ServiceInvoiceTable: React.FC<{
  adjustment?: InvoiceAdjustmentFormValues;
  editorState?: EditorState;
  patient: PatientData;
  invoiceNumber: string;
  invoiceCreatedAt: DateTime;
  onUpdate: (evt: ServiceInvoiceUpdateEvent) => void;
  language: PreferredLanguage;
}> = (props) => {
  const {t} = useTranslation(['adminOrders']);

  const mode = props.editorState ? 'editing' : 'viewing';

  return (
    <StyledTableWrapper>
      <StyledInvoiceTable
        id="invoice" // This ID should not be changed or removed, used for PDF generation
        mode={mode}
        unstackable={true}
      >
        <ServiceInvoiceTableHeader
          language={props.language}
          editorState={
            props.editorState
              ? {
                  onSelectAll: () =>
                    props.onUpdate({
                      action: 'toggleAll',
                      serviceIds:
                        props.patient.line_items.map((lineItem) => lineItem.service_id) ??
                        impossible(),
                    }),
                  allSelected:
                    props.editorState &&
                    props.patient.line_items.length > 0 &&
                    props.patient.line_items.every((item) =>
                      (props.editorState ?? impossible()).selectedServices.has(item.service_id)
                    ),
                }
              : undefined
          }
        />

        <tbody>
          {props.patient.line_items.length > 0 ? (
            props.patient.line_items.map((lineItem, index) => (
              <ServiceInvoiceLineItem
                language={props.language}
                key={index}
                lineItem={lineItem}
                editorState={
                  props.editorState
                    ? {
                        state: props.editorState,
                        onToggle: () =>
                          props.onUpdate({
                            action: 'toggleLineItem',
                            serviceId: lineItem.service_id,
                          }),
                      }
                    : undefined
                }
              />
            ))
          ) : (
            <tr>
              <StyledTdEmpty colSpan={mode === 'editing' ? 8 : 9}>
                <h3>{t('adminOrders:invoice.empty', {lng: props.language})}</h3>
              </StyledTdEmpty>
            </tr>
          )}
          {/* One last empty TR that will take up the remaining height of the table body */}
          <tr />
        </tbody>

        <ServiceInvoiceTableFooter
          adjustment={props.adjustment}
          language={props.language}
          editorState={props.editorState}
          health_insurance_number={props.patient.health_insurance_number}
          invoiceNumber={props.invoiceNumber}
          created_at={props.invoiceCreatedAt}
          total={props.patient.total}
          gst={props.patient.gst}
          qst={props.patient.qst}
        />
      </StyledInvoiceTable>
    </StyledTableWrapper>
  );
};

export default React.memo(ServiceInvoiceTable);
