import React, {ReactElement, ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import styled from '@emotion/styled';
import Icon, {IconName, isIconName} from '../icon';

export type AlertType = 'danger' | 'info' | 'success' | 'warning' | 'default' | 'turquoise';

export type AlertProps = {
  type: AlertType;
  icon?: IconName | ReactElement;
  title?: ReactNode;
  style?: any;
  className?: string;
  actionButton?: ReactNode;
};

const AlertStyles: Record<AlertType, SerializedStyles> = {
  danger: css`
    background-color: var(--medzy-color-red-bg);
    fill: var(--medzy-color-red-medium);
    color: var(--medzy-color-red-text);

    & > i.icon {
      color: var(--medzy-color-red-medium);
    }
  `,
  default: css`
    background-color: var(--medzy-color-neutral);
    fill: var(--medzy-color-night);
    color: var(--medzy-color-night);
  `,
  success: css`
    background-color: var(--medzy-color-green-bg);
    fill: var(--medzy-color-green-medium);
    color: var(--medzy-color-green-text);

    & > i.icon {
      color: var(--medzy-color-green-medium);
    }
  `,
  warning: css`
    background-color: var(--medzy-color-yellow-bg);
    fill: var(--medzy-color-yellow-medium);
    color: var(--medzy-color-yellow-text);

    & > i.icon {
      color: var(--medzy-color-yellow-medium);
    }
  `,
  info: css`
    background-color: var(--medzy-color-accent-bg);
    fill: var(--medzy-color-accent);
    color: var(--medzy-color-accent);
  `,
  turquoise: css`
    background-color: var(--medzy-color-turquoise-bg);
    fill: var(--medzy-color-turquoise-medium);
    color: var(--medzy-color-night);

    & > i.icon {
      color: var(--medzy-color-turquoise-medium);
    }
  `,
};

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  gap: 14px;

  & > svg,
  & > i.icon {
    flex: 0 0 auto;
    min-width: 24px;
    min-height: 24px;
    fill: inherit;
    font-size: 20px;
    color: inherit;
  }

  ${({type}: AlertProps) => AlertStyles[type]}
`;

const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 18px;

  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
  justify-self: flex-end;
`;

const Alert: React.FC<AlertProps> = (props) => {
  return (
    <Box type={props.type} style={props.style} className={props.className}>
      {props.icon ? isIconName(props.icon) ? <Icon name={props.icon} /> : props.icon : null}

      <Content>
        {props.title && <Title>{props.title}</Title>}
        {props.children}
      </Content>

      {props.actionButton && <ButtonContainer>{props.actionButton}</ButtonContainer>}
    </Box>
  );
};

export default Alert;
