import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {CompleteTenantConfig} from '@medzy/devops/src/global-config';
import {impossible} from '@medzy/functions/src/utils/assert';
import {getApp} from 'firebase/app';
import {FirebaseStorage, getStorage} from 'firebase/storage';
import store from 'store2';
import useGetProvidedTenant from 'ui/@hooks/use-get-provided-tenant';
import {useGlobalConfig} from './global-config-context';

export const formatTenantAddress = (
  tenantAddress: CompleteTenantConfig['address']
): [string, string] => {
  const {addressLine1, addressLine2, locality, region, postcode} = tenantAddress;

  return [
    [addressLine1, addressLine2].filter(Boolean).join(', '),
    `${locality}, ${region} ${postcode}`,
  ];
};

const TenantContext = createContext<{
  activeTenant: string;
  setActiveTenant: (tenant: string) => void;
  activeTenantConfig: CompleteTenantConfig;
  activeTenantStorage: FirebaseStorage;
}>(undefined!);

export const TenantProvider: React.FC = (props) => {
  const firebaseApp = getApp();
  const globalConfig = useGlobalConfig();
  const getProvidedTenant = useGetProvidedTenant();

  const [activeTenant, setActiveTenant] = useState<string | undefined>(undefined);

  const cleanUpUrl = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('tenant')) {
      queryParams.delete('tenant');
      location.search = queryParams.toString();
    }
  }, []);

  const setActiveTenantAndPersist = useCallback(
    (tenant: string) => {
      if (tenant === activeTenant) return;

      setActiveTenant(tenant);
      store.session.set('tenant', tenant);
      store.local.set('tenant', tenant);
      cleanUpUrl();
    },
    [activeTenant, cleanUpUrl]
  );

  useEffect(() => {
    if (activeTenant) return;

    const providedTenant = getProvidedTenant();
    setActiveTenantAndPersist(providedTenant ?? globalConfig.defaultTenant);
  }, [activeTenant, getProvidedTenant, globalConfig.defaultTenant, setActiveTenantAndPersist]);

  if (!activeTenant) return null;

  const activeTenantConfig = globalConfig.tenants[activeTenant];

  if (!activeTenantConfig) return null;

  if (activeTenantConfig.type !== 'complete') {
    return impossible('Webapp should only be used by complete tenants');
  }

  return (
    <TenantContext.Provider
      value={{
        activeTenant,
        setActiveTenant: setActiveTenantAndPersist,
        activeTenantConfig,
        activeTenantStorage: activeTenant
          ? getStorage(firebaseApp, `gs://${activeTenantConfig.storageBucket}`)
          : getStorage(),
      }}
    >
      {props.children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);

export default TenantContext;
