import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {getLocalizedRoutesMap, LocalizedRoute} from 'routes';
import {impossible} from 'utils/assert';

const useLocalizedRoutes = () => {
  const {i18n} = useTranslation();
  const {pathname} = useLocation();

  const localizedRoutesMap = useMemo(() => getLocalizedRoutesMap(i18n.language), [i18n.language]);

  const getRouteByName = useCallback(
    (name: string): LocalizedRoute =>
      localizedRoutesMap.get(name) ?? impossible(`Route "${name}" does not exist`),
    [localizedRoutesMap]
  );

  const getRouteByPath = useCallback(
    (pathname: string): LocalizedRoute | null =>
      Array.from(localizedRoutesMap.values()).find((route) => route.regex.test(pathname)) ?? null,
    [localizedRoutesMap]
  );

  const isCurrentRoute = useCallback(
    (name: string): boolean => name === getRouteByPath(pathname)?.name,
    [getRouteByPath, pathname]
  );

  return {localizedRoutesMap, getRouteByName, getRouteByPath, isCurrentRoute};
};

export default useLocalizedRoutes;
