import {useCallback, useEffect, useState} from 'react';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {codePush, InstallMode} from '@nerdfrenzs/capacitor-codepush';
import {SyncStatus} from '@nerdfrenzs/capacitor-codepush/dist/esm/syncStatus';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';
import env from 'config/environment';

const platform = Capacitor.getPlatform();

const useCodePush = (autoSync = true): {isInstallingMandatoryPackage: boolean} => {
  const {t} = useTranslation(['common']);

  const deploymentKey = env.codepush[platform]?.deploymentKey;
  const [isInstallingMandatoryPackage, setIsInstallingMandatoryPackage] = useState<boolean>(false);

  const doSyncWithPrompt = useCallback(async () => {
    await codePush.sync({
      updateDialog: {
        mandatoryContinueButtonLabel: t('codePush.mandatoryContinueButtonLabel'),
        mandatoryUpdateMessage: t('codePush.mandatoryUpdateMessage'),
        optionalIgnoreButtonLabel: t('codePush.optionalIgnoreButtonLabel'),
        optionalInstallButtonLabel: t('codePush.optionalInstallButtonLabel'),
        optionalUpdateMessage: t('codePush.optionalUpdateMessage'),
        updateTitle: t('codePush.updateTitle'),
      },
      installMode: InstallMode.ON_NEXT_RESUME,
      mandatoryInstallMode: InstallMode.IMMEDIATE,
      deploymentKey,
      onSyncStatusChanged: (status) => {
        switch (status) {
          case SyncStatus.DOWNLOADING_PACKAGE:
          case SyncStatus.INSTALLING_UPDATE:
            !isInstallingMandatoryPackage && setIsInstallingMandatoryPackage(true);
            break;
          default:
            isInstallingMandatoryPackage && setIsInstallingMandatoryPackage(false);
            break;
        }
      },
      onSyncError: (error) => {
        Sentry.captureException(error);
      },
    });
  }, [deploymentKey, isInstallingMandatoryPackage, t]);

  const doManualBackgroundSync = useCallback(async () => {
    await codePush.notifyApplicationReady();

    codePush.checkForUpdate(
      async (remotePackage) => {
        if (remotePackage) {
          // The hash of each previously reverted package is stored for later use.
          // This way, we avoid going into an infinite bad update/revert loop.
          if (!remotePackage.failedInstall) {
            try {
              setIsInstallingMandatoryPackage(remotePackage.isMandatory);

              const pendingPackage = await remotePackage.download();

              await pendingPackage.install({
                installMode: InstallMode.ON_NEXT_RESTART,
                mandatoryInstallMode: InstallMode.IMMEDIATE,
              });
            } catch (error) {
              Sentry.captureMessage(
                `Failed to install codepush remote package ${remotePackage.packageHash}`,
                {extra: {error}}
              );
            } finally {
              setIsInstallingMandatoryPackage(false);
            }
          }
        }
      },
      undefined,
      deploymentKey
    );
  }, [deploymentKey]);

  const doSyncForPlatform = platform === 'android' ? doSyncWithPrompt : doManualBackgroundSync;

  useEffect(() => {
    if (autoSync && deploymentKey) {
      void doSyncForPlatform();

      if (Capacitor.isPluginAvailable('App')) {
        void App.addListener('appStateChange', (state) => {
          if (state.isActive) {
            void doSyncForPlatform();
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSync, deploymentKey]);

  return {isInstallingMandatoryPackage};
};

export default useCodePush;
