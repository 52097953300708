import React from 'react';
import {css} from '@emotion/react';
import {ReactComponent as AddIcon} from './add.svg';
import {ReactComponent as Address} from './address.svg';
import {ReactComponent as AutoRenewIcon} from './auto-renew.svg';
import {ReactComponent as CheckIcon} from './check.svg';
import {ReactComponent as Cold} from './cold.svg';
import {ReactComponent as ContactIcon} from './contact.svg';
import {ReactComponent as DeliveredIcon} from './delivered.svg';
import {ReactComponent as DeliveryIcon} from './delivery.svg';
import {ReactComponent as FamilyMember} from './family-member.svg';
import {ReactComponent as InboxingIcon} from './inboxing.svg';
import {ReactComponent as Pet} from './pet.svg';
import {ReactComponent as PickUp} from './pick-up.svg';
import {ReactComponent as Plus} from './plus.svg';
import {ReactComponent as Profile} from './profile.svg';
import {ReactComponent as QuestionIcon} from './question.svg';
import {ReactComponent as RefreshIcon} from './refresh.svg';
import {ReactComponent as SuccessIcon} from './success.svg';
import {ReactComponent as WarningIcon} from './warning.svg';
import {ReactComponent as WorkInProgressIcon} from './work-in-progress.svg';
import {ReactComponent as XIcon} from './x.svg';

const IconNames = [
  'add',
  'address',
  'auto-renew',
  'check',
  'cold',
  'contact',
  'delivered',
  'delivery',
  'family-member',
  'inboxing',
  'pet',
  'pick-up',
  'plus',
  'profile',
  'question',
  'refresh',
  'success',
  'warning',
  'work-in-progress',
  'x',
] as const;

export type IconName = typeof IconNames[number];

export const isIconName = (name: unknown): name is IconName => {
  return typeof name === 'string' && IconNames.includes(name as IconName);
};

/**
 * Notes about this Icon Library:
 *
 * - Make sure your icon is the 20x20 format exported from Figma, it needs to have the padding around it
 * - Make sure you remove the fill color from the SVG, and make it inherit the fill property
 */

const IconLibrary: Record<
  IconName,
  React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>
> = {
  'add': AddIcon,
  'address': Address,
  'auto-renew': AutoRenewIcon,
  'check': CheckIcon,
  'cold': Cold,
  'contact': ContactIcon,
  'delivered': DeliveredIcon,
  'delivery': DeliveryIcon,
  'family-member': FamilyMember,
  'inboxing': InboxingIcon,
  'pet': Pet,
  'pick-up': PickUp,
  'plus': Plus,
  'profile': Profile,
  'question': QuestionIcon,
  'refresh': RefreshIcon,
  'success': SuccessIcon,
  'warning': WarningIcon,
  'work-in-progress': WorkInProgressIcon,
  'x': XIcon,
};

const Icon: React.FC<{name: IconName; fill?: string; title?: string}> = ({
  fill,
  title,
  name,
  ...props
}) => {
  const SelectedIcon = IconLibrary[name];

  return (
    <SelectedIcon
      className="medzy-icon"
      title={title ?? name}
      css={css`
        fill: ${fill ?? 'var(--medzy-color-night)'};
        stroke: none;
      `}
      {...props}
    />
  );
};

export default Icon;
