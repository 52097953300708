import React, {InputHTMLAttributes} from 'react';
import styled from '@emotion/styled';
import {ErrorMessage, FormControl} from '../forms';
import checkIcon from './check.svg';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  touched: boolean;
  error: any;
  label?: string;
  hasWarning?: boolean;
}

const Label = styled.label`
  display: flex;
  align-items: center;

  a {
    color: inherit;
  }
`;

const Input = styled.input`
  flex: 0 0 auto;
  width: 25px;
  height: 25px;
  border: 1px solid #94a2b4;
  border-radius: 4px;
  margin-right: 8px;
  background: #fff center no-repeat;
  background-size: 9px 9px;
  appearance: none;

  &:checked {
    background-image: url(${checkIcon});
  }

  &[disabled] {
    opacity: 0.15;
  }
`;

const WarningWrapper = styled.div`
  padding: 17px 18px 18px;
  border-radius: 4px;
  background-color: rgb(255 206 0 / 0.2);
  color: #cba60c;

  a {
    color: inherit !important;
  }

  input {
    border-color: #cba60c;
    margin-right: 15px;
  }
`;

const Checkbox: React.FC<CheckboxProps> = (props) => {
  return (
    <FormControl className={props.className}>
      {props.hasWarning ? (
        <WarningWrapper>
          <Label>
            <Input type="checkbox" {...props} />
            {props.label && <span dangerouslySetInnerHTML={{__html: props.label}}></span>}
          </Label>
        </WarningWrapper>
      ) : (
        <Label>
          <Input type="checkbox" {...props} />
          {props.label && <span dangerouslySetInnerHTML={{__html: props.label}}></span>}
        </Label>
      )}

      {props.touched && props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </FormControl>
  );
};

export default Checkbox;
