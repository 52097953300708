import styled from '@emotion/styled';

export const FormControl = styled.div`
  display: block;
  position: relative;
  min-height: 44px;
  margin: 0 0 1em;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: var(--medzy-color-night);

  a {
    color: inherit;
  }
`;

export const ErrorMessage = styled.div`
  display: block;
  margin-top: 3px;
  color: var(--medzy-color-red-medium);

  a {
    font-weight: bold;
    color: inherit;
  }
`;
