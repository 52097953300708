import {useCallback, useMemo} from 'react';
import axios from 'axios';
import {getPlatformAuth} from 'initializers/firebase';
import fetch from 'isomorphic-unfetch';
import {useTranslation} from 'react-i18next';
import env from 'config/environment';
import {Prescription_Upload_Insert_Input, Transaction_Insert_Input} from 'generated/graphql-types';
import {useTenant} from 'ui/@contexts/tenant-context';
import {CreditCard} from 'ui/admin/clients/single-client/@components/credit-cards-manager';
import {impossible} from 'utils/assert';

const useFirebaseFunctions = () => {
  const {i18n} = useTranslation();
  const auth = getPlatformAuth();
  const {activeTenant} = useTenant();

  /**
   * This is the most up-to-date way to query our firebase functions http backend.
   * All functions below this memoized Axios instance should be deprecated soon.
   */
  const functionsApiClient = useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: env.firebaseFunctions.url,
    });

    axiosInstance.interceptors.request.use(async (config) => {
      config.headers = {
        'Accept-Language': i18n.language,
        'X-Medzy-Tenant-Id': activeTenant,
      };
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }, Promise.reject);

    return axiosInstance;
    // Only create the Axios instance once, and use request interceptor to use most recent id token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHeaders = useCallback(async () => {
    // TODO: We should use a custom header to pass the locale, or maybe always use the patient's preferred_language
    // This is set by the browser and our value is currently overriden
    const headers = {
      'Accept-Language': i18n.language,
      ...(activeTenant && {'X-Medzy-Tenant-Id': activeTenant}),
    };
    if (!auth.currentUser) return headers;

    const token = await auth.currentUser.getIdToken();

    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }, [i18n.language, activeTenant, auth.currentUser]);

  const getSingleUseProfileToken = async (paysafeProfileId: string | null): Promise<any> => {
    if (!paysafeProfileId) return undefined;

    const headers = await getHeaders();

    const response = await fetch(
      `${env.firebaseFunctions.url}/paysafe/single-use-profile-token/${paysafeProfileId}`,
      {
        mode: 'cors',
        method: 'POST',
        headers,
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }

    const {paymentToken} = await response.json();

    return paymentToken;
  };

  const processPaysafeTransaction = async (
    transaction: unknown
  ): Promise<Transaction_Insert_Input | 'duplicate'> => {
    if (!transaction) return impossible('Called processPaysafeTransaction with no transaction');

    const headers = await getHeaders();

    const response = await fetch(`${env.firebaseFunctions.url}/paysafe/transaction`, {
      mode: 'cors',
      method: 'POST',
      headers,
      body: JSON.stringify(transaction),
    });

    if (response.status === 409) {
      // 409 = Conflict - Attempted duplicate transaction
      return 'duplicate';
    }

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`${response.status} ${response.statusText}: ${text}`);
    }

    return await response.json();
  };

  const addCardToPaysafe = async (data: {
    patientUid: string;
    paysafeProfileId: string | null;
    preferredLanguage: string;
    cardToken: string;
    defaultCardIndicator: boolean;
  }) => {
    const headers = await getHeaders();

    const response = await fetch(`${env.firebaseFunctions.url}/paysafe/cards`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const error = await response.json();
      throw error.error;
    }

    return response.json();
  };

  const getPaysafeCreditCards = async (patientUid?: string): Promise<CreditCard[]> => {
    const headers = await getHeaders();

    const response = await fetch(`${env.firebaseFunctions.url}/paysafe/cards`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        ...headers,
        ...(patientUid && {'patient-uid': patientUid}),
      },
    });

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }

    return response.json();
  };

  // TODO replace with GraphQL call to delete_paysafe_card_one
  const deletePaysafeCardForPatient = async (patientUid: string, cardId: string): Promise<void> => {
    const headers = await getHeaders();

    const response = await fetch(`${env.firebaseFunctions.url}/paysafe/cards/${cardId}`, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        ...headers,
        'patient-uid': patientUid,
      },
    });

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }
  };

  const getXpedigoShipmentDetailsWithLabel = async (shipmentId: string) => {
    const headers = await getHeaders();

    const response = await fetch(
      `${env.firebaseFunctions.url}/xpedigo/shipments/${shipmentId}?with_label=1`,
      {
        method: 'GET',
        headers,
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }

    return response.json();
  };

  const getPriceQuoteById = async (id: string, hash: string) => {
    const headers = await getHeaders();

    const response = await fetch(`${env.firebaseFunctions.url}/priceQuote/${id}/${hash}`, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }

    return response.json();
  };

  const registerForProactiveIntroductionCall = async (): Promise<void> => {
    const headers = await getHeaders();

    const response = await fetch(
      `${env.firebaseFunctions.url}/patientHttp/proactiveIntroductionCall/register`,
      {
        headers,
        method: 'POST',
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }
  };

  const createPrescription = async (
    prescription: Prescription_Upload_Insert_Input,
    patientUid: string
  ): Promise<void> => {
    const headers = await getHeaders();

    const response = await fetch(`${env.firebaseFunctions.url}/patientHttp/createPrescription`, {
      headers: {...headers, 'Content-Type': 'application/json'},
      method: 'POST',
      body: JSON.stringify({...prescription, patientUid}),
    });

    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.status} ${response.statusText}: ${text}`);
    }
  };

  return {
    addCardToPaysafe,
    createPrescription,
    deletePaysafeCardForPatient,
    getPaysafeCreditCards,
    getPriceQuoteById,
    getSingleUseProfileToken,
    getXpedigoShipmentDetailsWithLabel,
    processPaysafeTransaction,
    registerForProactiveIntroductionCall,
    functionsApiClient,
  };
};

export default useFirebaseFunctions;
