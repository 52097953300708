import account from './account.json';
import addresses from './addresses.json';
import adminClients from './adminClients.json';
import adminCommon from './adminCommon.json';
import adminConsultations from './adminConsultations.json';
import adminCreditCards from './adminCreditCards.json';
import adminFaxes from './adminFaxes.json';
import adminForms from './adminForms.json';
import adminMedication from './adminMedication.json';
import adminOrders from './adminOrders.json';
import adminOrdersToCreate from './adminOrdersToCreate.json';
import adminPatientsCare from './adminPatientsCare.json';
import adminPrescriptionRenewals from './adminPrescriptionRenewals.json';
import adminPriceQuotes from './adminPriceQuotes.json';
import adminReports from './adminReports.json';
import adminSearch from './adminSearch.json';
import adminTransferRequests from './adminTransferRequests.json';
import allergies from './allergies.json';
import automaticRenewals from './automaticRenewals.json';
import b2b from './b2b.json';
import common from './common.json';
import communicationPreferences from './communicationPreferences.json';
import consent from './consent.json';
import consultations from './consultations.json';
import createAccount from './createAccount.json';
import createPassword from './createPassword.json';
import creditCards from './creditCards.json';
import deliveryAddresses from './deliveryAddresses.json';
import family from './family.json';
import familyDoctor from './familyDoctor.json';
import forgotPassword from './forgotPassword.json';
import healthConditions from './healthConditions.json';
import healthProfile from './healthProfile.json';
import identityVerification from './identityVerification.json';
import insurance from './insurance.json';
import login from './login.json';
import logout from './logout.json';
import medication from './medication.json';
import opsManager from './opsManager.json';
import orders from './orders.json';
import otpVerification from './otpVerification.json';
import password from './password.json';
import paymentMethods from './paymentMethods.json';
import personalInformation from './personalInformation.json';
import prescription from './prescription.json';
import priceQuote from './priceQuote.json';
import publicInsurance from './publicInsurance.json';
import resetPassword from './resetPassword.json';
import shoppingCart from './shoppingCart.json';
import taxes from './taxes.json';
import verifyEmail from './verifyEmail.json';

export default {
  account,
  addresses,
  adminClients,
  adminCommon,
  adminConsultations,
  adminCreditCards,
  adminFaxes,
  adminForms,
  adminMedication,
  adminOrders,
  adminOrdersToCreate,
  adminPatientsCare,
  adminPrescriptionRenewals,
  adminPriceQuotes,
  adminReports,
  adminSearch,
  adminTransferRequests,
  allergies,
  automaticRenewals,
  b2b,
  common,
  communicationPreferences,
  consent,
  consultations,
  createAccount,
  createPassword,
  creditCards,
  deliveryAddresses,
  family,
  familyDoctor,
  forgotPassword,
  healthConditions,
  healthProfile,
  identityVerification,
  insurance,
  login,
  logout,
  medication,
  opsManager,
  orders,
  otpVerification,
  password,
  paymentMethods,
  personalInformation,
  prescription,
  priceQuote,
  publicInsurance,
  resetPassword,
  shoppingCart,
  taxes,
  verifyEmail,
};
