import React, {useEffect, useState, useMemo, useCallback} from 'react';
import * as Sentry from '@sentry/browser';
import {
  AuthError,
  FacebookAuthProvider,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  onIdTokenChanged,
  unlink,
  User,
} from 'firebase/auth';
import {getDatabase, ref, onValue} from 'firebase/database';
import {getPlatformAuth} from 'initializers/firebase';
import {useLocation} from 'react-router-dom';
import store from 'store2';
import FirebaseAuthContext from 'ui/@contexts/firebase-auth-context';
import {useTenant} from 'ui/@contexts/tenant-context';
import useFirebaseFunctions from 'ui/@hooks/use-firebase-functions';
import useLocalizedRoutes from 'ui/@hooks/use-localized-routes';
import {impossible} from 'utils/assert';

export const FIREBASE_ERRORS = {
  'auth/email-already-in-use': {
    email: 'already-in-use',
  },
  'auth/invalid-email': {
    email: 'invalid',
  },
  'auth/user-disabled': {
    email: 'disabled',
  },
  'auth/user-not-found': {
    email: 'not-found',
  },
  'auth/weak-password': {
    password: 'weak',
  },
  'auth/wrong-password': {
    password: 'wrong',
  },
  'oobCodeErrors': {
    'auth/expired-action-code': {
      oobCode: 'expired',
    },
    'auth/invalid-action-code': {
      oobCode: 'invalid',
    },
  },
  'updatePasswordErrors': {
    'auth/weak-password': {
      newPassword: 'weak',
    },
    'auth/wrong-password': {
      password: 'wrong',
    },
  },
};

export enum SignInProvider {
  Google,
  Facebook,
  Apple,
  Microsoft,
}

export const getProvider = (
  providerName: SignInProvider,
  language: string
): OAuthProvider | FacebookAuthProvider | GoogleAuthProvider => {
  const auth = getPlatformAuth();
  auth.languageCode = language;

  let provider: OAuthProvider | FacebookAuthProvider | GoogleAuthProvider;

  switch (providerName) {
    case SignInProvider.Apple:
      provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({
        locale: language,
      });
      break;

    case SignInProvider.Facebook:
      provider = new FacebookAuthProvider();
      provider.addScope('email');
      break;

    case SignInProvider.Google:
      provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      break;

    case SignInProvider.Microsoft:
      provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        locale: language,
      });
      break;
  }

  return provider;
};

export type AuthState = {
  status: 'in' | 'out' | 'verifying';
  user?: User;
  isAdmin?: boolean;
  isPatient?: boolean;
  isParentSession?: boolean;
  isAcquisitionLead?: boolean;
  hasAccountingRole?: boolean;
  token?: string;
};

export const isFirebaseAuthError = (e: any): e is AuthError => {
  return (
    'code' in e && typeof e.code === 'string' && 'message' in e && typeof e.message === 'string'
  );
};

const enforceAdminSignInMethods = async (user: User) => {
  const activeProviderIds = (user.providerData ?? [])
    .filter((providerData) => providerData !== null)
    .map((providerData) => providerData?.providerId ?? impossible());

  if (!activeProviderIds.some((providerId) => providerId === 'microsoft.com')) return;
  for (const providerId of activeProviderIds.filter(
    (providerId) => providerId !== 'microsoft.com'
  )) {
    await unlink(user, providerId);
  }
};

const FirebaseAuthProvider: React.FC = (props) => {
  const {children} = props;
  const [authState, setAuthState] = useState<AuthState>({status: 'verifying'});
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [signInWithRedirectError, setSignInWithRedirectError] = useState<AuthError | undefined>(
    undefined
  );
  const {getRouteByName} = useLocalizedRoutes();
  const location = useLocation();
  const auth = getPlatformAuth();
  const {activeTenant} = useTenant();

  const {functionsApiClient} = useFirebaseFunctions();

  const setCustomClaims = useCallback(async () => {
    const response = await functionsApiClient.post<{status: 'unchanged' | 'updated'}>(
      '/setCustomClaims'
    );
    return response.data.status;
  }, [functionsApiClient]);

  const refreshAuthState = useCallback(
    async (forceRefresh = false) => {
      if (isRedirecting) return;

      if (!auth.currentUser) {
        setAuthState({status: 'out'});
        Sentry.configureScope(function (scope) {
          scope.setUser(null);
        });
        return;
      }

      const freshToken = await auth.currentUser.getIdToken(forceRefresh);
      const idTokenResult = await auth.currentUser.getIdTokenResult(forceRefresh);

      // Currently active user claims
      const claims = (idTokenResult.claims ?? {}) as any;
      const hasuraClaims = claims.hasura?.[activeTenant];
      const allowedRoles = hasuraClaims?.['x-hasura-allowed-roles'] ?? [];
      const isAdmin = allowedRoles.indexOf('admin') >= 0;
      const isPatient = allowedRoles.indexOf('patient') >= 0;
      const isAcquisitionLead = allowedRoles.indexOf('acquisition-lead') >= 0;
      const hasAccountingRole = allowedRoles.indexOf('accounting') >= 0;

      // When impersonating a user
      const isParentSession = Boolean(claims.parentAdmin) && Boolean(claims.parentUid);

      const newAuthState = {
        status: 'in',
        token: freshToken,
        user: auth.currentUser,
        isAdmin,
        isPatient,
        isAcquisitionLead,
        isParentSession,
        hasAccountingRole,
      } as const;

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Refreshing state',
        level: 'info',
        data: {authState: newAuthState, hasuraClaims},
      });

      setAuthState(newAuthState);

      const userUid = auth.currentUser?.uid ?? undefined;
      const userEmail = auth.currentUser?.email ?? undefined;
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: userUid,
          username: userEmail,
          email: userEmail,
        });
      });
    },
    [auth.currentUser, isRedirecting, activeTenant]
  );

  useEffect(() => {
    (async () => {
      if (!auth.currentUser) return;

      try {
        setSignInWithRedirectError(undefined);
        const credential = await getRedirectResult(auth);
        if (location.pathname === getRouteByName('login').path) {
          store.session.set('provider-flow', {operationType: 'login'});

          if (!credential?.user) return;

          const {token: acquisitionLeadToken} = store.session.get('acquisition-lead-data') ?? {};
          if (acquisitionLeadToken) {
            await auth.currentUser?.getIdToken(true); // Get fresh id token before calling mergeLeadAccount
            await functionsApiClient.post(
              '/patientHttp/mergeLeadAccount',
              {
                originAccountToken: acquisitionLeadToken,
              },
              {validateStatus: (status) => status <= 500}
            );
          }
        }
      } catch (error) {
        if (!isFirebaseAuthError(error)) throw error;
        if (error.code === 'auth/operation-not-supported-in-this-environment') return;
        if (error.code === 'auth/argument-error') return;

        setAuthState({status: 'out'});
        setSignInWithRedirectError(error);
        return;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, isRedirecting]);

  useEffect(() => {
    return onIdTokenChanged(auth, async (_user) => {
      // Happens when the current Firebase user auth state changes (inside indexedDb or local storage)
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'onIdTokenChanged()',
        level: 'info',
      });
      await refreshAuthState();
    });
  }, [auth, refreshAuthState]);

  useEffect(() => {
    if (!auth.currentUser) return;

    const {uid} = auth.currentUser;
    const database = getDatabase();
    const metadataRef = ref(database, `metadata/${uid}/refreshTime`);

    return onValue(metadataRef, async () => {
      // Happens when our backends forces a token refresh (i.e. after updating claims, or verifying email)
      Sentry.addBreadcrumb({
        category: 'auth',
        message: `metadata/${uid}/refreshTime changed!`,
        level: 'info',
      });
      const status = await setCustomClaims();
      await refreshAuthState(status === 'updated');

      if (auth.currentUser && authState.isAdmin) {
        await enforceAdminSignInMethods(auth.currentUser);
      }
    });
  }, [auth.currentUser, authState.isAdmin, refreshAuthState, setCustomClaims]);

  const isUserAnonymous = useMemo(() => !authState.user || authState.user.isAnonymous, [authState]);
  const isAdmin = useMemo(() => authState.isAdmin, [authState]);
  const isPatient = useMemo(() => authState.isPatient, [authState]);
  const isAcquisitionLead = useMemo(() => authState.isAcquisitionLead, [authState]);
  const isParentSession = useMemo(() => authState.isParentSession, [authState]);
  const hasAccountingRole = useMemo(() => authState.hasAccountingRole, [authState]);

  return (
    <FirebaseAuthContext.Provider
      value={{
        authState,
        isAdmin,
        isPatient,
        isAcquisitionLead,
        isRedirecting,
        setIsRedirecting,
        isParentSession,
        isUserAnonymous,
        hasAccountingRole,
        signInWithRedirectError,
        setSignInWithRedirectError,
      }}
    >
      {authState.status !== 'verifying' && children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthProvider;
