import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import Spinner from '../spinner';

const Container = styled.div<{padding: number}>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${(props) => props.padding}px 0;
`;

const InvisibleSpinner = styled.div<{height: number}>`
  height: ${(props) => props.height}px;
`;

const Loader: React.FC<{
  delay?: number;
  size?: number;
  padding?: number;
  error?: any;
}> = ({delay = 500, size = 50, padding = 100, error, ...rest}) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const delayed = setTimeout(() => setReady(true), delay);

    return () => clearTimeout(delayed);
  }, [delay]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      Sentry.captureMessage('<Loader /> was loading for more than 30 seconds');
    }, 30_000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => error && window.location.reload(), [error]);

  return (
    <Container padding={padding} {...rest}>
      {ready ? <Spinner size={size} /> : <InvisibleSpinner height={size} />}
    </Container>
  );
};

export default Loader;
