import React, {ReactElement} from 'react';
import styled from '@emotion/styled';
import Icon, {IconName, isIconName} from 'ui/@components/icon';

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  text-align: center;
  gap: 8px;

  & > * {
    text-align: center;
  }

  @media (min-width: 993px) {
    gap: 4px;
  }
`;

const StyledIcon = styled(Icon)`
  min-width: 40px;
  min-height: 40px;

  @media (min-width: 993px) {
    min-width: 50px;
    min-height: 50px;
  }
`;

const PageHeader: React.FC<{icon?: IconName | ReactElement}> = ({icon, children, ...props}) => {
  return (
    <PageHeaderWrapper {...props}>
      {icon ? <div>{isIconName(icon) ? <StyledIcon name={icon} /> : icon}</div> : null}
      {children}
    </PageHeaderWrapper>
  );
};

export default PageHeader;
