import React from 'react';
import styled from '@emotion/styled';
import {ReactComponent as LogoEN} from 'images/Logo-EN.svg';
import {ReactComponent as LogoFR} from 'images/Logo-FR.svg';
import qs from 'query-string';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import PageHeader from 'ui/client/@components/pages/page-header';
import {head} from 'utils/array';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--medzy-color-neutral);
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  padding: 0 16px;
  margin: 15px 0 0;

  @media (max-width: 575px) {
    margin: 15px 0 0;
    text-align: center;
  }
`;

export const StyledPageHeader = styled(PageHeader)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 800px;
`;

export const IframeWrapper = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 800px;
  border-radius: 10px;
  margin: 10px 16px 0;
  background-color: #fff;
  box-shadow: rgb(149 157 165 / 0.2) 0 8px 24px;
`;

// https://medzy.leomed.co/embedded/forms/
const ALLOWED_URLS = [/^https:\/\/medzy\.leomed\.co\/embedded\/forms\/.*/];

const Embed: React.FC = () => {
  const {i18n} = useTranslation();
  const {search} = useLocation();

  const params = qs.parse(search);
  const url = (Array.isArray(params.url) ? head(params.url)?.trim() : params.url?.trim()) ?? '';

  if (!ALLOWED_URLS.some((allowedUrl) => allowedUrl.test(url))) {
    throw new Error(`Invalid embed URL: ${url}`);
  }

  return (
    <Container>
      <LogoWrapper>{i18n.language === 'en' ? <LogoEN /> : <LogoFR />}</LogoWrapper>

      <IframeWrapper>
        <iframe
          id="leomed"
          style={{overflow: 'hidden', width: '100%', flexGrow: 1}}
          width="100%"
          height="100%"
          src={url}
        />
      </IframeWrapper>
    </Container>
  );
};

export default Embed;
