import {ComponentType, lazy, LazyExoticComponent} from 'react';
import store from 'store2';

// Inspiration: https://rollbar.com/blog/javascript-chunk-load-error/
// Gist: https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
const lazyWithRetry = <T extends ComponentType<any>>(
  componentImport: () => Promise<{default: T}>
): LazyExoticComponent<T> =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      store.get('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      store.set('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        store.set('page-has-been-force-refreshed', 'true');
        return window.location.reload() as any;
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export default lazyWithRetry;
