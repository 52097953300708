import {z} from 'zod';
import {NonEmptyArray} from './array';

export const parseTenant = (tenant: unknown, configuredTenants: NonEmptyArray<string>) => {
  const tenantParseResult = z.enum(configuredTenants).safeParse(tenant);

  if (!tenantParseResult.success) return undefined;

  return tenantParseResult.data;
};
