import React, {useContext, useEffect, useState} from 'react';
import {signInWithCustomToken} from 'firebase/auth';
import {getPlatformAuth} from 'initializers/firebase';
import {DateTime} from 'luxon';
import qs from 'query-string';
import {Redirect, useLocation, useRouteMatch} from 'react-router-dom';
import {Config_Invoice_Type_Enum} from 'generated/graphql-types';
import PrintableServiceInvoiceContent from 'ui/@components/service-invoice/printable-service-invoice-content';
import {
  InvoiceDetailSchema,
  PreferredLanguage,
  stringToLanguage,
} from 'ui/@components/service-invoice/types';
import FirebaseAuthContext from 'ui/@contexts/firebase-auth-context';
import {head} from 'utils/array';
import {impossible} from 'utils/assert';
import {useInvoiceByIdLazyQuery} from './invoice-by-id.generated';

const InvoiceById: React.FC = () => {
  const {
    params: {id},
  } = useRouteMatch<{id: string}>();

  const location = useLocation();
  const {token} = qs.parse(location.search);
  const [idToken, setIdToken] = useState<string | undefined>();
  const {isAdmin} = useContext(FirebaseAuthContext);

  if (!token && !isAdmin) {
    return <Redirect to="/" />;
  }

  const [getInvoiceById, getInvoiceByIdQueryResult] = useInvoiceByIdLazyQuery({
    variables: {id: parseInt(id)},
    context: {token: idToken, role: token ? 'invoicing' : 'admin'},
  });

  useEffect(() => {
    (async () => {
      if (token && !idToken) {
        const auth = getPlatformAuth();
        const {user} = await signInWithCustomToken(auth, token as string);
        setIdToken(await (user ?? impossible()).getIdToken());
      }

      await getInvoiceById();
    })();
  }, [getInvoiceById, id, idToken, token]);

  if (!getInvoiceByIdQueryResult.called || getInvoiceByIdQueryResult.loading) {
    return null;
  }

  const invoice = getInvoiceByIdQueryResult.data?.invoice_by_pk;
  if (!invoice || invoice.type !== Config_Invoice_Type_Enum.Services) return null;

  const invoiceData = InvoiceDetailSchema.parse(
    invoice.details ?? impossible(`Could not parse details for invoice ${id}`)
  );
  if (invoiceData.version !== 'v1') impossible();

  const patientPreferredLanguage =
    getInvoiceByIdQueryResult.data?.invoice_by_pk?.order.patient.preferred_language;

  const invoiceLanguage =
    invoiceData.language ??
    (patientPreferredLanguage
      ? stringToLanguage(patientPreferredLanguage)
      : PreferredLanguage.French);

  const adjustment = head(invoice?.invoice_adjustments ?? []);

  return (
    <PrintableServiceInvoiceContent
      adjustment={{...adjustment, amount: (adjustment?.amount ?? 0) / 100}}
      language={invoiceLanguage}
      invoice={invoiceData}
      invoiceCreatedAt={DateTime.fromISO(invoice.created_at)}
      invoiceNumber={invoice.id.toString()}
    />
  );
};

export default InvoiceById;
