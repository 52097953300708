import env from '../config/environment';
import initializeEsShims from './es-shims';
import initializeFirebase from './firebase';
import initializeI18next from './i18next';
import initializeIonic from './ionic';
import initializeSentry from './sentry';

const initialize = () => {
  const i18next = initializeI18next();
  const firebase = initializeFirebase();

  initializeSentry();
  initializeIonic();
  initializeEsShims();

  // Note: Apollo is initialized on its own based on the tenant config

  if (env.featureFlags.xstateInspection) {
    (async () => {
      const {default: initializeXstateInspection} = await import('./xstate-inspection');
      initializeXstateInspection();
    })();
  }

  return {
    firebase,
    i18next,
  };
};

export default initialize;
