import {useCallback} from 'react';
import store from 'store2';
import {useGlobalConfig} from 'ui/@contexts/global-config-context';
import {parseTenant} from 'utils/tenant';

const useGetProvidedTenant = () => {
  const {configuredTenants} = useGlobalConfig();

  const getProvidedTenant = useCallback(() => {
    // Is it present in URL?
    const queryParams = new URLSearchParams(location.search);
    const tenantFromUrl = parseTenant(queryParams.get('tenant'), configuredTenants);
    if (tenantFromUrl) {
      store.session.set('tenant', tenantFromUrl);
      store.local.set('tenant', tenantFromUrl);
      return tenantFromUrl;
    }

    // Is it in session storage?
    const tenantFromSessionStorage = parseTenant(store.session.get('tenant'), configuredTenants);
    if (tenantFromSessionStorage) {
      store.local.set('tenant', tenantFromSessionStorage);
      return tenantFromSessionStorage;
    }

    // Is it in local storage?
    const tenantFromLocalStorage = parseTenant(store.local.get('tenant'), configuredTenants);
    if (tenantFromLocalStorage) {
      store.session.set('tenant', tenantFromLocalStorage);
      return tenantFromLocalStorage;
    }

    return undefined;
  }, [configuredTenants]);

  return getProvidedTenant;
};

export default useGetProvidedTenant;
