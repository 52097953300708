import React, {createContext, useContext, useEffect, useMemo} from 'react';
import {
  GlobalConfigV1,
  globalConfigSpecV1,
  staticGlobalConfigByProject,
} from '@medzy/devops/src/global-config';
import * as Sentry from '@sentry/react';
import {collection, doc, getDocs, getFirestore, onSnapshot} from 'firebase/firestore';
import env from 'config/environment';
import {NonEmptyArray} from 'utils/array';
import {impossible} from 'utils/assert';

const GlobalConfigContext = createContext<
  GlobalConfigV1 & {configuredTenants: NonEmptyArray<string>}
>(null as any);

export const GlobalConfigProvider: React.FC = (props) => {
  const projectId = env.firebase.projectId ?? impossible('Firebase projectId is undefined');
  const staticJsonConfigV1 = globalConfigSpecV1.parse(staticGlobalConfigByProject[projectId].v1);
  const [globalConfig, setGlobalConfig] = React.useState<GlobalConfigV1>(staticJsonConfigV1);
  const [isReady, setIsReady] = React.useState<boolean>(!env.application.globalConfigFromFirestore);

  useEffect(() => {
    if (!env.application.globalConfigFromFirestore) return;

    const db = getFirestore();
    const configV1Ref = doc(db, 'globalConfig', 'v1');
    const unsubscribe = onSnapshot(configV1Ref, async (firestoreConfigV1Snapshot) => {
      try {
        const firestoreConfigV1 = {
          // Config fields
          ...firestoreConfigV1Snapshot.data(),

          // Tenants config collection
          tenants: (await getDocs(collection(db, 'globalConfig', 'v1', 'tenants'))).docs.reduce(
            (acc, doc) => ({...acc, [doc.id]: doc.data()}),
            {}
          ),
        };

        setGlobalConfig(globalConfigSpecV1.parse({...staticJsonConfigV1, ...firestoreConfigV1}));
      } catch (error) {
        console.error(
          'Failed to parse Firestore runtime config, falling back to static runtime config',
          error
        );
        Sentry.captureException(error);
      } finally {
        setIsReady(true);
      }
    });

    return unsubscribe;
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configuredTenants = useMemo(
    () => Object.keys(globalConfig.tenants) as NonEmptyArray<string>,
    [globalConfig.tenants]
  );

  return (
    <GlobalConfigContext.Provider value={{...globalConfig, configuredTenants}}>
      {isReady ? props.children : null}
    </GlobalConfigContext.Provider>
  );
};

export const useGlobalConfig = () => useContext(GlobalConfigContext);

export default GlobalConfigContext;
