import {useTranslation} from 'react-i18next';

const useCurrencyFormat = (
  opts: {symbol: boolean} = {symbol: true}
): ((cents: number) => string) => {
  const {i18n} = useTranslation();

  const locale = `${i18n.language}-CA`;
  const formatter = new Intl.NumberFormat(
    locale,
    opts.symbol
      ? {
          style: 'currency',
          currency: 'CAD',
        }
      : {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}
  );

  return (cents: number) => formatter.format(cents / 100).replace('CA', '');
};

export default useCurrencyFormat;
