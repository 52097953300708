import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloLink,
  split,
  HttpLink,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import {CompleteTenantConfig} from '@medzy/devops/src/global-config';
import {SentryLink} from 'apollo-link-sentry';
import {onIdTokenChanged} from 'firebase/auth';
import {Client, ClientOptions, createClient} from 'graphql-ws';
import {getPlatformAuth} from 'initializers/firebase';
import fetch from 'isomorphic-unfetch';
import env from 'config/environment';
import DebounceLink from './debounce-link';

const getToken = async () => {
  const auth = getPlatformAuth();
  const token = auth.currentUser ? await auth.currentUser.getIdToken() : null;

  return token;
};

interface RestartableClient extends Client {
  restart(): void;
}

const initializeApollo = (activeTenant: string, activeTenantConfig: CompleteTenantConfig) => {
  // Taken from graphql-ws's graceful restart recipe:
  // https://github.com/enisdenjo/graphql-ws#graceful-restart
  function createRestartableClient(options: ClientOptions): RestartableClient {
    let restartRequested = false;
    let restart = () => {
      restartRequested = true;
    };

    const client = createClient({
      ...options,
      on: {
        ...options.on,
        opened: (socket: any) => {
          options.on?.opened?.(socket);

          restart = () => {
            if (socket.readyState === WebSocket.OPEN) {
              // if the socket is still open for the restart, do the restart
              socket.close(4205, 'Client Restart');
            } else {
              // otherwise the socket might've closed, indicate that you want
              // a restart on the next opened event
              restartRequested = true;
            }
          };

          // just in case you were eager to restart
          if (restartRequested) {
            restartRequested = false;
            restart();
          }
        },
      },
    });

    return {
      ...client,
      restart: () => restart(),
    };
  }

  const wsClient = createRestartableClient({
    url: `${activeTenantConfig.apiWsBaseUrl}/${env.apollo.apiGraphqlPath}`,
    connectionAckWaitTimeout: 10000,
    shouldRetry: () => true,
    connectionParams: async () => {
      const token = await getToken();

      if (!token)
        return {
          headers: {
            'x-hasura-role': 'anonymous',
          },
        };

      return {
        headers: {
          'authorization': `Bearer ${token}`,
          'x-medzy-tenant-id': activeTenant,
        },
      };
    },
  });

  const wsLink = new GraphQLWsLink(wsClient);

  const httpLink = new HttpLink({
    uri: `${activeTenantConfig.apiBaseUrl}/${env.apollo.apiGraphqlPath}`,
    fetch,
  });

  const debouncedHttpLink = ApolloLink.from([new DebounceLink(300), httpLink]);

  const authLink = setContext(async (request, previousContext) => {
    const token = previousContext.token ?? (await getToken());

    if (!token) return previousContext;

    return {
      ...previousContext,
      headers: {
        ...previousContext?.headers,
        'authorization': `Bearer ${token}`,
        'x-medzy-tenant-id': activeTenant,
      },
    };
  });

  const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        switch (error.extensions.code) {
          case 'invalid-jwt':
          case 'access-denied':
            wsClient.restart();
            // retry the request, returning the new observable
            return forward(operation);
          default:
            return;
        }
      });
    }

    if (networkError) {
      console.error('[Network error]:', networkError);
    }
  });

  const retryLink = new RetryLink();

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeError: true,
      includeContext: ['headers'],
    },
  });

  const terminatingLink = split(
    // split based on operation type
    ({query}) => {
      const mainDefinition = getMainDefinition(query);

      if (mainDefinition.kind !== 'OperationDefinition') return false;

      return mainDefinition.operation === 'subscription';
    },
    wsLink,
    debouncedHttpLink
  );

  const link = ApolloLink.from([authLink, retryLink, errorLink, sentryLink, terminatingLink]);

  const auth = getPlatformAuth();
  onIdTokenChanged(auth, wsClient.restart);

  return {
    apolloClient: new ApolloClient({
      cache: new InMemoryCache({
        dataIdFromObject: (object) => {
          switch (object.__typename) {
            case 'account':
            case 'admin':
            case 'patient':
              return `${object.__typename}:${object['uid']}`;
            case 'patient_and_acquired_patient':
              return `${object.__typename}:${object['uid'] ?? object['id']}`;
            default:
              return defaultDataIdFromObject(object);
          }
        },
      }),
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    }),
  };
};

export default initializeApollo;
