const toHumanReadable = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = /^(1|)?(\d{3})(\d{3})(\d{4})$/.exec(cleaned);

  if (!match) return phone;

  // const intlCode = match[1] ? '+1 ' : '';

  return ['(', match[2], ') ', match[3], '‑', match[4]].join('');
};

export default toHumanReadable;
