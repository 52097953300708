import {useEffect} from 'react';
import {useIonViewDidEnter} from '@ionic/react';

const useTitle = (title: string) => {
  useEffect(() => {
    window.document.title = title;
  }, [title]);

  useIonViewDidEnter(() => {
    window.document.title = title;
  });
};

export default useTitle;
