import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import {ReactComponent as OriginalSpinner} from './spinner.svg';

const rotate = keyframes`
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled(OriginalSpinner)<{size: number}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: ${rotate} 1.5s linear infinite;
`;

export default Spinner;
