import React from 'react';
import {DateTime} from 'luxon';
import {InvoiceAdjustmentFormValues} from 'ui/admin/orders/single-order/@components/invoice-info/invoice-adjustment/invoice-adjustment';
import ServiceInvoiceHeader from './service-invoice-header';
import {StyledPrintableInvoiceContentContainer, StyledInvoiceContentContainer} from './styles';
import ServiceInvoiceTable from './table/service-invoice-table';
import {InvoiceData, PreferredLanguage} from './types';

const PrintableServiceInvoiceContent: React.FC<{
  adjustment?: InvoiceAdjustmentFormValues;
  invoice: InvoiceData;
  language: PreferredLanguage;
  invoiceNumber: string;
  invoiceCreatedAt: DateTime;
}> = (props) => {
  return (
    <>
      {[...props.invoice.patients.values()].map((patient, index) => (
        <StyledPrintableInvoiceContentContainer key={index}>
          <StyledInvoiceContentContainer>
            <ServiceInvoiceHeader tabType="patient" patient={patient} language={props.language} />
            <ServiceInvoiceTable
              adjustment={props.adjustment}
              language={props.language}
              patient={patient}
              invoiceNumber={props.invoiceNumber}
              invoiceCreatedAt={props.invoiceCreatedAt}
              onUpdate={() => null}
            />
          </StyledInvoiceContentContainer>
        </StyledPrintableInvoiceContentContainer>
      ))}
    </>
  );
};

export default React.memo(PrintableServiceInvoiceContent);
