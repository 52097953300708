import styled from '@emotion/styled';

const Alerts = styled.div`
  @media (max-width: 768px) {
    flex: 1 1 auto;
    padding: 10px 0 0;
  }
`;

export default Alerts;
