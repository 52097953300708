import {pathToRegexp} from 'path-to-regexp';
import {impossible} from 'utils/assert';

type Paths = {
  en: string;
  fr: string;
};

export type LocalizedRoute = {
  name: string;
  path: string;
  alternatePath: string;
  regex: RegExp;
  exact?: boolean;
  paths: Paths;
  enableIonic: boolean;
  isRootPage: boolean;
};

interface RouteDefinition {
  name: string;
  paths: Paths;
  routes?: RouteDefinition[];
  exact?: boolean;
  enableIonic?: boolean;
  isRootPage?: boolean;
}

const routes: RouteDefinition[] = [
  {
    name: 'action',
    paths: {
      en: '/action',
      fr: '/action',
    },
  },
  {
    name: 'short-url',
    enableIonic: true,
    isRootPage: true,
    paths: {
      en: '/s/:hash',
      fr: '/s/:hash',
    },
  },
  {
    name: 'embed',
    paths: {
      en: '/e',
      fr: '/e',
    },
  },
  {
    name: 'leomed-form',
    paths: {
      en: '/form',
      fr: '/fr/formulaire',
    },
  },
  {
    name: 'form-by-id',
    paths: {
      en: '/form/:id',
      fr: '/fr/formulaire/:id',
    },
  },
  {
    name: 'price-quote',
    paths: {
      en: '/price-request',
      fr: '/fr/demande-de-prix',
    },
    routes: [
      {
        name: 'price-quote-by-id',
        paths: {
          en: '/price-request/:id/:hash',
          fr: '/fr/demande-de-prix/:id/:hash',
        },
      },
    ],
  },
  {
    name: 'landing',
    paths: {
      en: '/en',
      fr: '/fr',
    },
  },
  {
    name: 'redirection',
    paths: {
      en: '/redirection',
      fr: '/fr/redirection',
    },
  },
  {
    name: 'login',
    paths: {
      en: '/login',
      fr: '/fr/connexion',
    },
  },
  {
    name: 'logout',
    paths: {
      en: '/logout',
      fr: '/fr/deconnexion',
    },
  },
  {
    name: 'sign-up',
    paths: {
      en: '/sign-up',
      fr: '/fr/inscription',
    },
    routes: [
      {
        name: 'verification',
        paths: {
          en: '/sign-up/verification',
          fr: '/fr/inscription/validation',
        },
      },
    ],
  },
  {
    name: 'forgot-password',
    paths: {
      en: '/forgot-password',
      fr: '/fr/mot-de-passe-oublie',
    },
  },
  {
    name: 'home',
    enableIonic: true,
    isRootPage: true,
    paths: {
      en: '/home',
      fr: '/fr/accueil',
    },
  },
  {
    name: 'medication',
    enableIonic: true,
    isRootPage: true,
    paths: {
      en: '/medication',
      fr: '/fr/medicaments',
    },
    routes: [
      {
        name: 'medication-by-id',
        enableIonic: true,
        paths: {
          en: '/medication/:id',
          fr: '/fr/medicaments/:id',
        },
      },
      {
        name: 'single-prescription',
        enableIonic: true,
        paths: {
          en: '/medication/prescriptions/:id',
          fr: '/fr/medicaments/ordonnances/:id',
        },
      },
      {
        name: 'single-transfer',
        enableIonic: true,
        paths: {
          en: '/medication/transfers/:id',
          fr: '/fr/medicaments/transferts/:id',
        },
      },
      {
        name: 'auto-renew',
        enableIonic: true,
        paths: {
          en: '/medication/auto-renew',
          fr: '/fr/medicaments/auto-renouvellement',
        },
      },
    ],
  },
  {
    name: 'follow-ups',
    exact: true,
    enableIonic: true,
    paths: {
      en: '/consultations',
      fr: '/fr/consultations',
    },
    routes: [
      {
        name: 'consultations-history',
        enableIonic: true,
        paths: {
          en: '/consultations/history',
          fr: '/fr/consultations/historique',
        },
      },
    ],
  },
  {
    name: 'single-consultation',
    enableIonic: true,
    exact: true,
    paths: {
      en: '/consultations/:id',
      fr: '/fr/consultations/:id',
    },
  },
  {
    name: 'shopping-cart',
    enableIonic: true,
    exact: true,
    paths: {
      en: '/shopping-cart',
      fr: '/fr/panier',
    },
  },
  {
    name: 'orders',
    enableIonic: true,
    isRootPage: true,
    paths: {
      en: '/orders',
      fr: '/fr/commandes',
    },
    routes: [
      {
        name: 'single-order',
        enableIonic: true,
        paths: {
          en: '/orders/:id',
          fr: '/fr/commandes/:id',
        },
        routes: [
          {
            name: 'order-payment-method',
            enableIonic: true,
            paths: {
              en: '/orders/:id/payment-method',
              fr: '/fr/commandes/:id/mode-de-paiement',
            },
          },
          {
            name: 'automatic-renewals',
            enableIonic: true,
            paths: {
              en: '/orders/automatic-renewals',
              fr: '/fr/commandes/renouvellements-automatiques',
            },
          },
        ],
      },
    ],
  },
  {
    name: 'messages',
    enableIonic: true,
    isRootPage: true,
    paths: {
      en: '/messages',
      fr: '/fr/messages',
    },
  },
  {
    name: 'account',
    enableIonic: true,
    isRootPage: true,
    paths: {
      en: '/account',
      fr: '/fr/compte',
    },
    routes: [
      {
        name: 'profile',
        enableIonic: true,
        paths: {
          en: '/account/profile/:uid?',
          fr: '/fr/compte/profil/:uid?',
        },
        routes: [
          {
            name: 'profile-personal-information',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/personal-information',
              fr: '/fr/compte/profil/:uid/informations-personnelles',
            },
          },
          {
            name: 'profile-pet-information',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/pet-information',
              fr: '/fr/compte/profil/:uid/informations-sur-l-animal',
            },
          },
          {
            name: 'profile-public-insurance',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/ramq-card',
              fr: '/fr/compte/profil/:uid/carte-ramq',
            },
          },
          {
            name: 'profile-health-conditions',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/health-conditions',
              fr: '/fr/compte/profil/:uid/conditions-de-sante',
            },
          },
          {
            name: 'profile-pet-health-conditions',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/pet-health-conditions',
              fr: '/fr/compte/profil/:uid/conditions-de-sante-de-l-animal',
            },
          },
          {
            name: 'profile-allergies',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/allergies',
              fr: '/fr/compte/profil/:uid/allergies',
            },
          },
          {
            name: 'profile-pet-allergies',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/pet-allergies',
              fr: '/fr/compte/profil/:uid/allergies-de-l-animal',
            },
          },
          {
            name: 'profile-family-doctor',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/family-doctor',
              fr: '/fr/compte/profil/:uid/medecin-de-famille',
            },
          },
          {
            name: 'profile-consent',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/consent',
              fr: '/fr/compte/profil/:uid/consentement',
            },
          },
          {
            name: 'profile-private-insurance',
            enableIonic: true,
            paths: {
              en: '/account/profile/:uid/private-insurance',
              fr: '/fr/compte/profil/:uid/assurance-privee',
            },
          },
        ],
      },
      {
        name: 'delivery-addresses',
        enableIonic: true,
        paths: {
          en: '/account/delivery-addresses',
          fr: '/fr/compte/adresses-de-livraison',
        },
        routes: [
          {
            name: 'delivery-address-by-id',
            enableIonic: true,
            paths: {
              en: '/account/delivery-addresses/:id',
              fr: '/fr/compte/adresses-de-livraison/:id',
            },
          },
        ],
      },
      {
        name: 'payment-methods',
        enableIonic: true,
        paths: {
          en: '/account/payment-methods',
          fr: '/fr/compte/modes-de-paiement',
        },
        routes: [
          {
            name: 'payment-method-by-id',
            enableIonic: true,
            paths: {
              en: '/account/payment-methods/:id',
              fr: '/fr/compte/modes-de-paiement/:id',
            },
          },
          {
            name: 'automatic-payments-consent',
            enableIonic: true,
            paths: {
              en: '/account/payment-methods/automatic-payments-consent',
              fr: '/fr/compte/modes-de-paiement/consentement-paiements-automatiques',
            },
          },
        ],
      },
      {
        name: 'insurances',
        enableIonic: true,
        paths: {
          en: '/account/insurances',
          fr: '/fr/compte/assurances',
        },
        routes: [
          {
            name: 'public-insurance-by-id',
            enableIonic: true,
            paths: {
              en: '/account/public-insurance/:id',
              fr: '/fr/compte/assurance-publique/:id',
            },
          },
          {
            name: 'private-insurance-by-id',
            enableIonic: true,
            paths: {
              en: '/account/private-insurance/:id',
              fr: '/fr/compte/assurance-privee/:id',
            },
          },
        ],
      },
      {
        name: 'communication-preferences',
        enableIonic: true,
        paths: {
          en: '/account/communication-preferences',
          fr: '/fr/compte/preferences-de-contact',
        },
      },
      {
        name: 'password',
        enableIonic: true,
        paths: {
          en: '/account/password',
          fr: '/fr/compte/mot-de-passe',
        },
      },
      {
        name: 'app-language',
        enableIonic: true,
        paths: {
          en: '/account/application-language',
          fr: '/fr/compte/langue-de-l’application',
        },
      },
      {
        name: 'taxes',
        enableIonic: true,
        paths: {
          en: '/account/taxes',
          fr: '/fr/compte/impots',
        },
      },
      {
        name: 'delete-account',
        enableIonic: true,
        paths: {
          en: '/account/delete-account',
          fr: '/fr/compte/supression-de-compte',
        },
      },
    ],
  },
  {
    name: 'admin',
    paths: {
      en: '/admin',
      fr: '/admin',
    },
    routes: [
      {
        name: 'admin-search',
        paths: {
          en: '/admin/search',
          fr: '/admin/search',
        },
      },
      {
        name: 'admin-clients',
        paths: {
          en: '/admin/clients',
          fr: '/admin/clients',
        },
        routes: [
          {
            name: 'admin-single-client',
            paths: {
              en: '/admin/clients/:uid',
              fr: '/admin/clients/:uid',
            },
            routes: [
              {
                name: 'admin-client-addresses',
                paths: {
                  en: '/admin/clients/:uid/addresses',
                  fr: '/admin/clients/:uid/addresses',
                },
              },
              {
                name: 'admin-client-credit-cards',
                paths: {
                  en: '/admin/clients/:uid/credit-cards',
                  fr: '/admin/clients/:uid/credit-cards',
                },
              },
              {
                name: 'admin-client-orders',
                paths: {
                  en: '/admin/clients/:uid/orders',
                  fr: '/admin/clients/:uid/orders',
                },
              },
              {
                name: 'admin-client-medication',
                paths: {
                  en: '/admin/clients/:uid/medication',
                  fr: '/admin/clients/:uid/medication',
                },
                routes: [
                  {
                    name: 'admin-client-medication-by-id',
                    paths: {
                      en: '/admin/clients/:uid/medication/:id',
                      fr: '/admin/clients/:uid/medication/:id',
                    },
                  },
                ],
              },
              {
                name: 'admin-client-insurance',
                paths: {
                  en: '/admin/clients/:uid/insurance',
                  fr: '/admin/clients/:uid/insurance',
                },
              },
              {
                name: 'admin-client-transfers',
                paths: {
                  en: '/admin/clients/:uid/transfers',
                  fr: '/admin/clients/:uid/transfers',
                },
              },
              {
                name: 'admin-client-documents',
                paths: {
                  en: '/admin/clients/:uid/documents',
                  fr: '/admin/clients/:uid/documents',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'admin-orders',
        paths: {
          en: '/admin/orders',
          fr: '/admin/orders',
        },
        routes: [
          {
            name: 'admin-single-order',
            paths: {
              en: '/admin/orders/:id',
              fr: '/admin/orders/:id',
            },
            routes: [
              {
                name: 'admin-single-order-leaflet',
                paths: {
                  en: '/admin/orders/:id/leaflet',
                  fr: '/admin/orders/:id/leaflet',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'admin-single-prescription-renewal',
        paths: {
          en: '/admin/prescription-renewals/:id',
          fr: '/admin/prescription-renewals/:id',
        },
        routes: [
          {
            name: 'admin-prescription-renewals-medication-by-id',
            paths: {
              en: '/admin/prescription-renewals/:id/medication/:id',
              fr: '/admin/prescription-renewals/:id/medication/:id',
            },
          },
        ],
      },
      {
        name: 'admin-price-quotes',
        paths: {
          en: '/admin/price-quotes',
          fr: '/admin/price-quotes',
        },
        routes: [
          {
            name: 'admin-single-price-quote',
            paths: {
              en: '/admin/price-quotes/:id',
              fr: '/admin/price-quotes/:id',
            },
          },
        ],
      },
      {
        name: 'admin-consultations',
        paths: {
          en: '/admin/consultations',
          fr: '/admin/consultations',
        },
      },
      {
        name: 'admin-patients-care',
        paths: {
          en: '/admin/patients-care',
          fr: '/admin/patients-care',
        },
      },
      {
        name: 'admin-transfer-requests',
        paths: {
          en: '/admin/transfer-requests',
          fr: '/admin/transfer-requests',
        },
      },
      {
        name: 'admin-orders-to-create',
        paths: {
          en: '/admin/orders-to-create',
          fr: '/admin/orders-to-create',
        },
      },
      {
        name: 'admin-forms',
        paths: {
          en: '/admin/forms',
          fr: '/admin/forms',
        },
        routes: [
          {
            name: 'admin-form-by-id',
            paths: {
              en: '/admin/forms/:formId',
              fr: '/admin/forms/:formId',
            },
          },
        ],
      },
      {
        name: 'admin-reports',
        paths: {
          en: '/admin/reports',
          fr: '/admin/reports',
        },
        routes: [
          {
            name: 'admin-reports-shipments',
            paths: {
              en: '/admin/reports/shipments',
              fr: '/admin/reports/shipments',
            },
          },
          {
            name: 'admin-reports-daily-shipments',
            paths: {
              en: '/admin/reports/daily-shipments',
              fr: '/admin/reports/daily-shipments',
            },
          },
          {
            name: 'admin-reports-orphaned-services',
            paths: {
              en: '/admin/reports/orphaned-services',
              fr: '/admin/reports/orphaned-services',
            },
          },
          {
            name: 'admin-reports-upcoming-shipments',
            paths: {
              en: '/admin/reports/upcoming-shipments',
              fr: '/admin/reports/upcoming-shipments',
            },
          },
        ],
      },
      {
        name: 'admin-faxes',
        paths: {
          en: '/admin/faxes',
          fr: '/admin/faxes',
        },
        routes: [
          {
            name: 'admin-send-fax',
            paths: {
              en: '/admin/faxes/send',
              fr: '/admin/faxes/send',
            },
          },
          {
            name: 'admin-single-fax',
            paths: {
              en: '/admin/faxes/:id',
              fr: '/admin/faxes/:id',
            },
          },
        ],
      },
      {
        name: 'admin-ops-manager',
        paths: {
          en: '/admin/ops-manager',
          fr: '/admin/ops-manager',
        },
        routes: [
          {
            name: 'admin-ops-manager-accounts-to-create-in-logipharm',
            paths: {
              en: '/admin/ops-manager/accounts-to-create-in-logipharm',
              fr: '/admin/ops-manager/accounts-to-create-in-logipharm',
            },
          },
          {
            name: 'admin-ops-manager-prescription-uploads',
            paths: {
              en: '/admin/ops-manager/prescription-uploads',
              fr: '/admin/ops-manager/prescription-uploads',
            },
          },
          {
            name: 'admin-ops-manager-refill-requests',
            paths: {
              en: '/admin/ops-manager/refill-requests',
              fr: '/admin/ops-manager/refill-requests',
            },
          },
          {
            name: 'admin-ops-manager-prescription-renewal-requests',
            paths: {
              en: '/admin/ops-manager/prescription-renewal-requests',
              fr: '/admin/ops-manager/prescription-renewal-requests',
            },
          },
          {
            name: 'admin-ops-manager-patients-to-call',
            paths: {
              en: '/admin/ops-manager/patients-to-call',
              fr: '/admin/ops-manager/patients-to-call',
            },
          },
          {
            name: 'admin-ops-manager-orders',
            paths: {
              en: '/admin/ops-manager/orders',
              fr: '/admin/ops-manager/orders',
            },
          },
          {
            name: 'admin-ops-manager-public-insurances-to-validate',
            paths: {
              en: '/admin/ops-manager/public-insurances-to-validate',
              fr: '/admin/ops-manager/public-insurances-to-validate',
            },
          },
        ],
      },
    ],
  },
];

const mapRoutes = (
  routes: RouteDefinition[],
  locale: string = 'en',
  map: Map<string, LocalizedRoute> = new Map()
) => {
  routes.forEach((route) => {
    const {name, paths, routes, enableIonic = false, isRootPage = false} = route;

    if (routes) {
      mapRoutes(routes, locale, map);
    }

    const existingRoute = map.get(name);
    if (process.env.NODE_ENV !== 'production' && existingRoute) {
      console.warn(
        `Duplicate definition for route ${name}:\n- ${existingRoute.path}\n- ${JSON.stringify(
          paths,
          null,
          2
        )}`
      );
    }

    map.set(name, {
      name,
      path: paths[locale],
      alternatePath: paths[locale === 'en' ? 'fr' : 'en'] ?? impossible(),
      regex: pathToRegexp(paths[locale]),
      paths,
      enableIonic,
      isRootPage,
    });
  });

  return map;
};

export const getLocalizedRoutesMap = (locale: string): Map<string, LocalizedRoute> =>
  mapRoutes(routes, locale);
