import {FirebaseAppCheck} from '@capacitor-firebase/app-check';
import {Capacitor} from '@capacitor/core';
import {FirebaseApp, initializeApp} from 'firebase/app';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  CustomProvider,
  AppCheckToken,
} from 'firebase/app-check';
import {
  Auth,
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import Cookies from 'js-cookie';
import env from 'config/environment';

let firebase: FirebaseApp;
let auth: Auth;

export const getPlatformAuth = () => {
  if (auth) return auth;

  if (!firebase) {
    initializeFirebase();
  }

  auth = Capacitor.isNativePlatform()
    ? initializeAuth(firebase, {
        persistence: [indexedDBLocalPersistence, browserSessionPersistence],
      })
    : getAuth(firebase);

  return auth;
};

const initializeFirebaseAppCheck = async (app: FirebaseApp) => {
  const debugToken = Cookies.get('FIREBASE_APPCHECK_DEBUG_TOKEN');
  if (debugToken) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = debugToken;
  }

  let provider;
  if (Capacitor.isNativePlatform()) {
    await FirebaseAppCheck.initialize({
      isTokenAutoRefreshEnabled: true,
    });

    provider = new CustomProvider({
      getToken: () => {
        return FirebaseAppCheck.getToken() as Promise<AppCheckToken>;
      },
    });
  } else {
    provider = new ReCaptchaEnterpriseProvider(env.recaptchaEnterprise.siteKey);
  }

  initializeAppCheck(app, {provider});
};

const initializeFirebase = () => {
  if (!firebase) {
    firebase = initializeApp(env.firebase, {});

    void initializeFirebaseAppCheck(firebase);
  }

  return firebase;
};

export default initializeFirebase;
