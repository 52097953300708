import {useEffect} from 'react';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {useHistory} from 'react-router-dom';

interface AppUrlOpen {
  url: string;
}

const useDeepLinking = (): void => {
  let history = useHistory();

  useEffect(() => {
    if (Capacitor.isPluginAvailable('App')) {
      void App.addListener('appUrlOpen', (data: AppUrlOpen) => {
        if (!data?.url) return;

        const url = new URL(data.url);
        const path = url.pathname + url.search;

        history.push(path);
      });
    }
    // Should run only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDeepLinking;
