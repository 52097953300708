import React from 'react';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import useCurrencyFormat from 'ui/@hooks/use-currency-format';
import {InvoiceAdjustmentFormValues} from 'ui/admin/orders/single-order/@components/invoice-info/invoice-adjustment/invoice-adjustment';
import {
  StyledTd,
  StyledTotal,
  StyledMoney,
  StyledCode,
  StyledInvoiceDate,
  StyledReceiptNumber,
  StyledHealthInsurance,
} from '../styles';
import {EditorState, PreferredLanguage} from '../types';

const ServiceInvoiceTableFooter: React.FC<{
  adjustment?: InvoiceAdjustmentFormValues;
  editorState?: EditorState;
  health_insurance_number?: string;
  invoiceNumber: string;
  total: number;
  gst: number;
  qst: number;
  created_at: DateTime;
  language: PreferredLanguage;
}> = (props) => {
  const {health_insurance_number, total, created_at, invoiceNumber, editorState} = props;
  const {t} = useTranslation(['adminOrders']);
  const formatCurrency = useCurrencyFormat();

  const adjustmentAmount = props.adjustment?.amount ?? 0;
  const adjustmentReason = props.adjustment?.reason?.label;

  return (
    <tfoot>
      <tr>
        <StyledTd colSpan={editorState ? 6 : 5}>
          {health_insurance_number && (
            <StyledHealthInsurance>
              <label>{t('adminOrders:invoice.ramq', {lng: props.language})}</label>
              {health_insurance_number}
            </StyledHealthInsurance>
          )}
        </StyledTd>
        <StyledTd colSpan={2}>
          {props.gst !== 0 && (
            <StyledCode>{t('adminOrders:invoice.gst', {lng: props.language})}</StyledCode>
          )}
          {props.qst !== 0 && (
            <StyledCode>{t('adminOrders:invoice.qst', {lng: props.language})}</StyledCode>
          )}
          {adjustmentAmount !== 0 && (
            <StyledCode>{`${t('adminOrders:invoice.adjustment', {lng: props.language})}${
              adjustmentReason ? ` - ${adjustmentReason}` : ''
            }`}</StyledCode>
          )}
          <StyledTotal>{t('adminOrders:invoice.totalToPay', {lng: props.language})}</StyledTotal>
        </StyledTd>
        <StyledTd money={true}>
          {props.gst !== 0 && <StyledMoney>{formatCurrency(props.gst)}</StyledMoney>}
          {props.qst !== 0 && <StyledMoney>{formatCurrency(props.qst)}</StyledMoney>}
          {adjustmentAmount !== 0 && <StyledMoney>{formatCurrency(adjustmentAmount)}</StyledMoney>}
          <StyledTotal>{formatCurrency(total + adjustmentAmount)}</StyledTotal>
        </StyledTd>
      </tr>
      <tr>
        <StyledTd colSpan={editorState ? 6 : 5}>
          {created_at && (
            <StyledInvoiceDate>
              {t('adminOrders:invoice.date', {lng: props.language})}
              {': '}
              {props.created_at.toLocaleString(DateTime.DATE_SHORT, {
                locale: props.language,
              })}
            </StyledInvoiceDate>
          )}
        </StyledTd>
        <StyledTd colSpan={3}>
          <StyledReceiptNumber>
            {t('adminOrders:invoice.officialInvoice', {lng: props.language})} {'#'} {invoiceNumber}
          </StyledReceiptNumber>
        </StyledTd>
      </tr>
    </tfoot>
  );
};

export default ServiceInvoiceTableFooter;
