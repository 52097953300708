import React from 'react';
import {compile} from 'path-to-regexp';
import {Redirect, RedirectProps} from 'react-router-dom';
import useLocalizedRoutes from 'ui/@hooks/use-localized-routes';

export interface LocalizedRedirectProps<TParams = object> extends RedirectProps {
  to: any;
  params?: TParams;
}

const LocalizedRedirect: React.FC<LocalizedRedirectProps> = ({to, params, ...rest}) => {
  const {getRouteByName} = useLocalizedRoutes();

  if (params) {
    const pathTo = compile(getRouteByName(to).path);

    return <Redirect to={pathTo(params)} {...rest} />;
  }

  return <Redirect to={getRouteByName(to).path} {...rest} />;
};

export default LocalizedRedirect;
