import {Capacitor} from '@capacitor/core';
import {setupIonicReact} from '@ionic/react';

enum IonicMode {
  MeterialDesign = 'md',
  iOS = 'ios',
}

const initializeIonic = (): void => {
  setupIonicReact({
    animated: true,
    backButtonText: '',
    mode: IonicMode.iOS,
    swipeBackEnabled: Capacitor.isNativePlatform(),
  });
};

export default initializeIonic;
