import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {Suspense} from 'react';
import {ApolloProvider} from '@apollo/client';
import {IonReactRouter} from '@ionic/react-router';
import initialize from 'initializers';
import initializeApolloClient from 'initializers/apollo';
import {render} from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {GlobalConfigProvider} from 'ui/@contexts/global-config-context';
import TenantContext, {TenantProvider} from 'ui/@contexts/tenant-context';
import Application from 'ui/application';
import FirebaseAuthProvider from 'ui/application/@components/firebase-auth-provider';
import {impossible} from 'utils/assert';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

const {i18next} = initialize();

render(
  <Suspense fallback={null}>
    <GlobalConfigProvider>
      <TenantProvider>
        <IonReactRouter>
          <FirebaseAuthProvider>
            <TenantContext.Consumer>
              {({activeTenant, activeTenantConfig}) => {
                const {apolloClient} = initializeApolloClient(
                  activeTenant ?? impossible('No active tenant'),
                  activeTenantConfig ?? impossible('No active tenant config')
                );

                return (
                  <ApolloProvider client={apolloClient}>
                    <I18nextProvider i18n={i18next}>
                      <Application />
                    </I18nextProvider>
                  </ApolloProvider>
                );
              }}
            </TenantContext.Consumer>
          </FirebaseAuthProvider>
        </IonReactRouter>
      </TenantProvider>
    </GlobalConfigProvider>
  </Suspense>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
