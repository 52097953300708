import React from 'react';
import {formatName} from '@medzy/functions/src/utils/name';
import {ReactComponent as LogoEn} from 'images/Logo-EN.svg';
import {ReactComponent as LogoFr} from 'images/Logo-FR.svg';
import {useTranslation} from 'react-i18next';
import {formatTenantAddress, useTenant} from 'ui/@contexts/tenant-context';
import {toHumanReadable} from 'utils/phone';
import {
  StyledHeader,
  StyledPatientInformationContainer,
  StyledSheetName,
  StyledPharmacyInformationContainer,
  StyledPatientAddressContainer,
  StyledAddressDiv,
} from './styles';
import {PatientData, PreferredLanguage} from './types';

const ServiceInvoiceHeader: React.FC<
  {language: PreferredLanguage} & (
    | {
        tabType: 'patient';
        patient: PatientData;
      }
    | {
        tabType: 'summary';
      }
    | {
        tabType: 'creditNote';
      }
  )
> = (props) => {
  const {t} = useTranslation('adminOrders');
  const {activeTenantConfig} = useTenant();

  const [addressLine1, addressLine2] = formatTenantAddress(activeTenantConfig.address);

  const sheetName =
    props.tabType === 'patient'
      ? formatName(props.patient)
      : props.tabType === 'creditNote'
      ? t('adminOrders:invoice.creditNote.title', {lng: props.language})
      : t('adminOrders:invoice.invoiceSummary', {lng: props.language});

  return (
    <StyledHeader>
      <StyledPharmacyInformationContainer>
        {props.language === 'en' ? <LogoEn /> : <LogoFr />}

        <div>
          <StyledAddressDiv light>{addressLine1}</StyledAddressDiv>
          <StyledAddressDiv light>{addressLine2}</StyledAddressDiv>
          <StyledAddressDiv light>
            {t('common:support.phone', {lng: props.language})}
          </StyledAddressDiv>
        </div>
      </StyledPharmacyInformationContainer>

      <StyledPatientInformationContainer>
        <StyledSheetName>{sheetName}</StyledSheetName>

        {props.tabType === 'patient' && (
          <StyledPatientAddressContainer>
            <StyledAddressDiv>{props.patient?.address_line1}</StyledAddressDiv>
            <StyledAddressDiv>{props.patient?.address_line2}</StyledAddressDiv>
            <StyledAddressDiv>
              {props.patient?.phone_number && toHumanReadable(props.patient?.phone_number)}
            </StyledAddressDiv>
          </StyledPatientAddressContainer>
        )}
      </StyledPatientInformationContainer>
    </StyledHeader>
  );
};

export default React.memo(ServiceInvoiceHeader);
