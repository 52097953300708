import React from 'react';
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import Checkbox from '../../checkbox';
import {StyledTh} from '../styles';
import {PreferredLanguage} from '../types';

type ServiceInvoiceTableHeaderProps = {
  editorState?: {
    allSelected: boolean;
    onSelectAll: () => void;
  };
  language: PreferredLanguage;
};

const ServiceInvoiceTableHeader: React.FC<ServiceInvoiceTableHeaderProps> = (props) => {
  const {t: _t} = useTranslation(['adminOrders']);
  const t = (s: string) => _t(s, {lng: props.language});

  return (
    <thead>
      <tr>
        {props.editorState && (
          <StyledTh checkbox={true} smaller={true}>
            <Checkbox
              touched={false}
              error={null}
              onChange={props.editorState.onSelectAll}
              checked={props.editorState.allSelected}
            />
          </StyledTh>
        )}
        <StyledTh allBold={true} small={true}>
          <div>{t('adminOrders:invoice.line.quantity')}</div>
          <div>{t('adminOrders:invoice.line.rxNumber')}</div>
          <div>{t('adminOrders:invoice.line.din')}</div>
        </StyledTh>
        <StyledTh allBold={true}>
          <div>{t('adminOrders:invoice.line.medicationName')}</div>
          <div>{t('adminOrders:invoice.line.prescriber')}</div>
          <div>{t('adminOrders:invoice.line.insurance')}</div>
        </StyledTh>
        <StyledTh small={true}>
          <div>{t('adminOrders:invoice.line.totalPrice')}</div>
          <div>{t('adminOrders:invoice.line.medicationPrice')}</div>
        </StyledTh>
        <StyledTh small={true}>
          <div>{t('adminOrders:invoice.line.RGAMPrice')}</div>
          <div>{t('adminOrders:invoice.line.distributorPrice')}</div>
        </StyledTh>
        <StyledTh
          small={true}
          css={css`
            min-width: 168px; /* "Montant assuré" will take 1 line instead of 2 */
          `}
        >
          <div>{t('adminOrders:invoice.line.insuredAmount')}</div>
          <div>{t('adminOrders:invoice.line.professionalFees')}</div>
        </StyledTh>
        <StyledTh smaller={true}>{t('adminOrders:invoice.line.deductible')}</StyledTh>
        <StyledTh smaller={true}>{t('adminOrders:invoice.line.copay')}</StyledTh>
        <StyledTh smaller={true}>{t('adminOrders:invoice.line.toPay')}</StyledTh>
      </tr>
    </thead>
  );
};

export default React.memo(ServiceInvoiceTableHeader);
